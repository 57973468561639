import React, { useState } from 'react';
import './skip-to-main-content.css';

const SkipToMainContent = () => {
  const [visible, setVisible] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      setVisible(true);
    }
  };

  const handleBlur = () => {
    setVisible(false);
  };

  return (
    <div
      className={`skip-link ${visible ? 'visible' : 'hidden'}`}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
    >
      <a href="#main-content">Skip to main content</a>
    </div>
  );
};

export default SkipToMainContent;
