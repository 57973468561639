import React from "react";
import ReactHtmlParser from "react-html-parser";
import './list-item.css';

const ListItem = ({item, sibling}) => {
const Element = item.tag;

    return ( 
      <li>
       {Element && <Element href={item.url && item.url} src={item.source ?? undefined} id={item.id && item.id} >
        {item.text}
       </Element>}
       {ReactHtmlParser(sibling)}
      </li>
    );
  };  
  

export default ListItem;
