import React from "react";
import ListItem from "./list-item";
import './list.css';

const List = ({ containerClass, items }) => {
    return ( 
      <ul  className={containerClass}>
          {items.map((item, index) => (
            <ListItem item={item} key={index} sibling={item.sibling}/>
          ))}
      </ul>
    );
  };
  

export default List;
