import React, { useState, useEffect } from "react";
import "./menu-burger.css";
import burger from "../assets/icons/burger-bar.png";
import logo from "../assets/whiteLogo.png";
import List from "./list";
import BuyButton from "./buy-button";

const BurgerMenu = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
  const mobileMenuItems = [
    {
      tag: "a",
      text: "About",
      url: "#about-section",
    },
    {
      tag: "a",
      text: "How to Play",
      url: "#how-to-play-section",
    },
    {
      tag: "a",
      text: "Resources",
      url: "#resources-section",
    },
    {
      tag: "a",
      text: "Meet the team",
      url: "#meet-the-team-section",
    },
  ];
  const menuItems = (
    <nav className={!isMobile ? "web-nav" : ""}>
       {!isMobile && <img
          src={logo}
          alt="FIX IT! The game"
          className="web-header-logo"
          onClick={() => (window.location.hash = "#about-section")}
        />}
      <List containerClass="menu-items" items={mobileMenuItems} />
      {!isMobile && <BuyButton />}
    </nav>
  );

  return (
    <>
     {isMobile && (
      <div className={`burger-menu ${isOpen ? "open" : "closed"}`}>
          <img className="menu-burger" src={burger} onClick={toggleMenu} alt="Burger Icon" />
        <BuyButton />
        <img
          src={logo}
          alt="FIX IT! The game"
          className="header-logo"
          onClick={() => (window.location.hash = "#about-section")}
        />
      </div>)}
      {isMobile ? isOpen && menuItems : menuItems}
    </>
  );
};

export default BurgerMenu;
