import React from 'react';
import './card-counter.css';

const CardCounter = ({ cards, containerClass }) => {
  return (
    <div className={containerClass}>
      {cards.map((card, index) => (
        <div key={index} className="card">
          <h3 style={{ backgroundColor: card.titleColour}}>{card.title}</h3>
          <p>{card.text}</p>
        </div>
      ))}
    </div>
  );
};

export default CardCounter;
