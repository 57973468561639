import React, { useState } from "react";
import "./team-card.css";

// If we go back to create the cards here
// const TeamCard = ({ color, title, source, icon, description }) => {
//   return (
//     <div className="team-card">
//       <p className={`card-title ${color}`}>{title}</p>
//       <img className="team-img" src={source} alt="" />
//       <div className="team-card-description-container">
//       <p>
//         {icon && <span>
//           <img width="16px" src={icon} alt="" />{" "}
//         </span>}
//         {description}
//       </p>
//       </div>
//     </div>
//   );
// };

const TeamCard = ({ source, text, hoverSource }) => {
  const [isHovered, setIsHovered] = useState(false);

//   return source ? <img src={source} alt="" className="team-card" /> : <p className="thanks-p">{text}</p>;
// };
// export default TeamCard;

  return (
    <>
      {source ? (
        <img
          src={isHovered ? hoverSource : source}
          alt=""
          className="team-card"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            transition: 'opacity 0.5s ease-in-out',
          }}
        />
      ) : (
        <p className="thanks-p">{text}</p>
      )}
    </>
  );
};

export default TeamCard;
